import React, { useEffect, useState } from "react";
import location from "../../../assest/img/location.svg";
import axios from "axios";
import UpdateLocation from "./UpdateLocation";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function Address({
  setDisplayedAddres,
  LngAndLatOfMap,
  setLngAndLatOfMap,
  addressDetails,
  Slog,
  setAddressDetails,
  DisplayedAddres,
}) {
  const [ChoseLcationOpen, setChoseLcationOpen] = useState(false);
  const [EditAddressIsOpen, setEditAddressIsOpen] = useState(false);
  const [NewDisplayedAddres, setNewDisplayedAddres] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  const handleInputChange = (field, value) => {
    setAddressDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };
 
  useEffect(()=>{
    setLngAndLatOfMap(null)
  },[EditAddressIsOpen])
  useEffect(() => {
    if (NewDisplayedAddres) return;
    setNewDisplayedAddres(DisplayedAddres);
  }, [DisplayedAddres]);

  async function HandelUpdateLocation(location, addressDetails) {
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    try {
      const response = await axios.post(
        `https://visiontunisie.com/api/v1/GestionDesBiens/UpdateAdresse`,
        {
          Bien: {
            Slog: Slog,
            ID_Proprietaire: UserId,
            PositionLatitude: location.lat,
            PositionLongitude: location.lng,
            AdresseLigne1: addressDetails.streetAddress || "",
            AdresseLigne2: addressDetails.Gouvernorat || "",
            AdresseLigne3:
              addressDetails.Delegation + addressDetails.postalCode || "",
            AdresseLigne4: addressDetails.country || "",
            Gouvernorat: addressDetails.Gouvernorat,
            Delegation: addressDetails.Delegation,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setNewDisplayedAddres(addressDetails.streetAddress);
      toast.success(t("Msgs.Updatesuccessful", { text: t("Labels.Address") }));
    } catch (error) {
      throw new Error(`Error fetching geocoding data: ${error.message}`);
    }
  }

  return (
    <div className=" flex flex-col gap-2 ">
      <h1 className=" font-semibold text-lg pb-3"> {t("Labels.Address")}</h1>
        <UpdateLocation
          Location={DisplayedAddres}
          setLocation={setDisplayedAddres}
          HandelUpdateLocation={HandelUpdateLocation}
          LngAndLatOfMap={LngAndLatOfMap}
          setLngAndLatOfMap={setLngAndLatOfMap}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          isOpen={EditAddressIsOpen}
          setIsOpen={setEditAddressIsOpen}
          update={true}
        />
      <div
        onClick={() => {
          setEditAddressIsOpen(!EditAddressIsOpen);
          setChoseLcationOpen(true);
        }}
        className="text-left cursor-pointer hover:shadow-md duration-200  w-full lg:w-[50%] py-3 items-start gap-2 px-[1rem]  flex flex-row  rounded-md border-solid border-[1px]"
      >
        <img className=" w-[1.2rem] pt-1 " src={location} alt="location-icon" />
        {NewDisplayedAddres ? (
          <p> {NewDisplayedAddres}</p>
        ) : (
          <span className=" flex flex-col">
            <h1 className="  font-semibold"> {t("Host.Map.YourAddress")}</h1>
            <p className=" text-xs text-gray-400">
              {t("Host.Map.ClickToAddYourAddress")}
            </p>
          </span>
        )}
      </div>
    </div>
  );
}

export default Address;
