// SocketContext.js
import Cookies from "js-cookie";
import React, { createContext, useState, useEffect, useRef } from "react";
import io from "socket.io-client";

// Import the sound file
import notificationSound from "../assest/nitificationSound.mp3"; // adjust the path as necessary

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [sendrMessages, setSendrMessages] = useState([]);
  const socketRef = useRef();
  const userToken = Cookies.get("Token");

  useEffect(() => {
    const initializeSocket = async () => {
      socketRef.current = io("https://realtime.visiontunisie.com");

      socketRef.current.on("connect", () => {
         
        socketRef.current.emit("register", { token: userToken });
      });

      socketRef.current.on("message", (data) => {
 
        setMessages((prevMessages) => [...prevMessages || [], data]);
        // Play sound when a message is received
        console.log(messages)
        const audio = new Audio(notificationSound);
        audio.play();
      });
    };

    initializeSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const sendMessage = (message, conversationUID) => {
    if (socketRef.current && userToken) {
      socketRef.current.emit("private_message", {
        token: userToken,
        conversationUID: conversationUID,
        message: message,
      });
    }
  };

  return (
    <SocketContext.Provider value={{ userToken, messages, sendMessage, setMessages , sendrMessages, setSendrMessages }}>
      {children}
    </SocketContext.Provider>
  );
};
