import {
  GoogleMap,
  MarkerF,
  CircleF,
} from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import markerImage from "../../assest/img/markre.png";
import hiddenLocationImage from "../../assest/img/hiddenLocation.png";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "60vh",
};

const Map = ({
  Ishost,
  LngAndLatOfMap,
  setLngAndLatOfMap,
  LngAndLatOfSearch,
  lat,
  lng,
  RayonSurCarte,
}) => {
  const { t } = useTranslation();
  

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyD0mFEe70wKfw9RtgZpzlvNPfzwWft3jMk",
  //   libraries,
  // });

  const [Location, setLocation] = useState({
    lat: parseFloat(lat) || 33.8869,
    lng: parseFloat(lng) || 9.5375,
  });

  useEffect(() => {
    setLocation({
      lat: parseFloat(lat) || 33.8869,
      lng: parseFloat(lng) || 9.5375,
    });
  }, [lng, lat]);

  const onMarkerDrag = (e) => {
    let newLat = e.latLng.lat();
    let newLng = e.latLng.lng();
    setLocation({
      lat: newLat,
      lng: newLng,
    });
    setLngAndLatOfMap({
      lat: newLat,
      lng: newLng,
    });
  };

  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }

  // if (!isLoaded) {
  //   return <div>Loading maps...</div>;
  // }

  return (
    <div className="flex flex-col gap-[1rem]">
      <GoogleMap
        key={Math.random()} // or use a unique identifier if available
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        options={{
          clickableIcons: false,
        }}
        center={Ishost ? LngAndLatOfMap || LngAndLatOfSearch : Location}
      >
        {!Ishost && RayonSurCarte <= 0 && (
          <MarkerF
            position={ Location}
            icon={{ url: markerImage }}
          />
        )}

        {Ishost && (
          <MarkerF
            draggable={Ishost}
            onDragEnd={onMarkerDrag}
            position={LngAndLatOfMap || LngAndLatOfSearch}
            icon={{ url: markerImage }}
          />
        )}

        {RayonSurCarte > 0 && (
          <CircleF
            center={Location}
            radius={10 * 300}
            options={{
              fillColor: "#4285F4",
              fillOpacity: 0.2,
              strokeColor: "#4285F4",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
