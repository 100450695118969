import React, { useContext, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import GrayLine from "../ui/GrayLine";
import { Usercontext } from "../../context/Usercontexte";
function NewPriceDetailsModal({
  data,
  isOpen,
  setIsOpen,
  Total,
  IsResrvationDetails,
}) {
  const { currency , exchangeRate } = useContext(Usercontext);

  return (
    <>
      {isOpen && (
        <div
          className={`absolute  max-h-[60vh] z-[200] ${
            IsResrvationDetails
              ? "top-[-50%]  left-[0%]  "
              : "top-[-100%]  left-[0%]  lg:left-[-75%]"
          }  w-[20rem] shadow-xl rounded-lg border-[1px]     bg-white flex gap-3 flex-col `}
        >
          <span className=" pt-3  font-medium text-center items-center justify-center w-full flex  ">
            <span
              onClick={() => setIsOpen(false)}
              className=" text-[.95rem] cursor-pointer z-50 scale-75 absolute top-3 left-2 "
            >
              <CloseIcon />
            </span>{" "}
            Base Price Breakdown
          </span>
          <GrayLine />
          <span className=" flex flex-col overflow-auto font-medium px-3 py-2 gap-3 ">
            {data?.map((newPrice) => (
              <div className=" flex text-[.9rem]  items-center justify-between px-1">
                <p>{newPrice?.date} </p>
                <p>
                  {(newPrice?.price * exchangeRate).toFixed(2)}{" "}
                  <span className=" text-xs">{currency.symbol}</span>{" "}
                </p>
              </div>
            ))}
          </span>
          <span className=" px-3">
            <GrayLine />
          </span>
          <span className=" font-medium flex w-full justify-between px-3 pb-3 ">
            Total :{" "}
            <p>
              {" "}
              {parseFloat(Total).toFixed(2)} <span className=" text-xs">{currency.symbol}</span>{" "}
            </p>
          </span>
        </div>
      )}
    </>
  );
}

export default NewPriceDetailsModal;
