import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function SynchronisationCal({
  CalendrUID,
  AirbnbLink,
  BookingLink,
  setBookingLink,
  Slog,
  setAirbnbLink,
  fetchDataBienDetails
}) {
  const [message, setMessage] = useState("");
  const [newAirbnbLink, setNewAirbnbLink] = useState("");
  const [newBookingLink, setNewBookingLink] = useState("");

  const [URLairBnbIsLoading, setURLairBnbIsLoading] = useState(false);
  const [URLBookingIsLoading, setURLBookingIsLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const SuperHostLink = `https://visiontunisie.com/api/v1/VCALENDAR/${CalendrUID}`;
  useEffect(() => {
    setNewBookingLink(BookingLink);
    setNewAirbnbLink(AirbnbLink);
  }, [AirbnbLink, BookingLink]);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(SuperHostLink)
      .then(() => {
        setMessage("CalendrUID copied to clipboard!");
        setTimeout(() => setMessage(""), 1000); // Clear message after 3 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setMessage("Failed to copy CalendrUID");
        setTimeout(() => setMessage(""), 1000); // Clear message after 3 seconds
      });
  };

  function UpdateURLairBnb(data) {
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    setURLairBnbIsLoading(true);
    const updateTitreData = {
      Slog: Slog,
      URLairBnb: newAirbnbLink,
      ID_Proprietaire: UserId,
    };
    axios
      .post(
        "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateURLairBnb",
        updateTitreData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setAirbnbLink(newAirbnbLink);
        setURLairBnbIsLoading(false);
        fetchDataBienDetails()
        toast.success(
          t("Msgs.Updatesuccessful", {
            text: `Airbnb.com ${t("Host.AddAirbnbLink.CalenderLink")} `,
          })
        );
      })
      .catch((error) => {
        setURLairBnbIsLoading(false);
        console.error("Error:", error);
      });
  }

  function UpdateURLbooking(data) {
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    setURLBookingIsLoading(true);

    const updateTitreData = {
      Slog: Slog,
      URLbooking: newBookingLink,
      ID_Proprietaire: UserId,
    };

    axios
      .post(
        "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateURLbooking",
        updateTitreData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setBookingLink(newBookingLink);
        setURLBookingIsLoading(false);
        fetchDataBienDetails()

        toast.success(
          t("Msgs.Updatesuccessful", {
            text: `Booking.com ${t("Host.AddAirbnbLink.CalenderLink")} `,
          })
        );
      })
      .catch((error) => {
        // Handle errors here
        setURLBookingIsLoading(false);
        console.error("Error:", error);
      });
  }

  return (
    <div className=" w-full flex flex-col gap-5 relative ">
      <h1 className=" font-semibold text-lg">
        {t("Host.AddAirbnbLink.synchronisationCal")}
      </h1>
      {/* -----------calendr SuperHost-----------  */}
      <div className="flex flex-col gap-2">
        <h2 className="font-semibold">
          {" "}
          SuperHost {t("Host.AddAirbnbLink.CalenderLink")}{" "}
        </h2>
        <span className="relative w-full">
          <input
            disabled
            readOnly
            value={SuperHostLink}
            type="text"
            className="w-full border-main px-4 h-[3rem] border-[1px] rounded-lg"
          />
          <button
            onClick={handleCopyClick}
            className={`  text-sm px-4  h-[2rem] absolute top-2 ${
              message ? "bg-sec " : "bg-main"
            }  text-white rounded-md right-2`}
          >
            {message ? t("Labels.Copied") + "!" : t("Labels.copy")}
          </button>
        </span>
      </div>

      {/* -----------calendr Airbnb-----------  */}
      <div className="flex flex-col gap-2">
        <h1 className="font-semibold">
          {" "}
          Airbnb.com {t("Host.AddAirbnbLink.CalenderLink")} : 
        </h1>
        <span className="relative w-full">
          <input
            onChange={(e) => setNewAirbnbLink(e.target.value)}
            value={newAirbnbLink}
            type="text"
            placeholder={` Airbnb.com ${t("Host.AddAirbnbLink.CalenderLink")}`}
            className="w-full border-main px-4 h-[3rem] border-[1px] rounded-lg"
          />
          <button
            onClick={UpdateURLairBnb}
            className={`    text-sm px-4  h-[2rem] absolute top-2 bg-main  text-white rounded-md right-2`}
          >
            {URLairBnbIsLoading ? (
              <Oval
                visible={true}
                height="20"
                width="20"
                secondaryColor="white"
                color="white"
              />
            ) : (
              t("Actions.Save")
            )}
          </button>
        </span>
      </div>

      {/* -----------calendr Booking-----------  */}
      <div className="flex flex-col gap-2">
        <h1 className="font-semibold">
          {" "}
          Booking.com {t("Host.AddAirbnbLink.CalenderLink")} :{" "}
        </h1>
        <span className="relative w-full">
          <input
            onChange={(e) => setNewBookingLink(e.target.value)}
            value={newBookingLink}
            type="text"
            placeholder={`Booking.com ${t("Host.AddAirbnbLink.CalenderLink")}`}
            className="w-full border-main px-4 h-[3rem] border-[1px] rounded-lg"
          />
          <button
            onClick={UpdateURLbooking}
            className={`  text-sm px-4  h-[2rem] absolute top-2 bg-main  text-white rounded-md right-2`}
          >
            {URLBookingIsLoading ? (
              <Oval
                visible={true}
                height="20"
                width="20"
                secondaryColor="white"
                color="white"
              />
            ) : (
              t("Actions.Save")
            )}
          </button>
        </span>
      </div>
    </div>
  );
}

export default SynchronisationCal;
