export const UploadImg = async (files) => {
 // Assuming there is an API endpoint to upload the image to the server
 
 
 try {
    const formData = new FormData();
 formData.append("file", files); // Assuming 'image' is the name of the form field for the image

 const response = await fetch("https://visiontunisie.com/api/v1/upload", {
   method: "POST",
   body: formData,
 });

 if (!response.ok) {
   throw new Error("Failed to upload image to the server");
 }

 const { imageUrl } = await response.json(); // Assuming the server responds with the image URL
  
  return imageUrl;

 } catch (error) {
  
 }

 

} 