import React, { useState, useEffect, useRef } from "react";
import { useClickOutside } from "../../hooks/UseClickOutSide";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import LoadingBtn from "../ui/LoadingBtn";
import SynchronisationCal from "../Dashboard/Calender/SynchronisationCal.jsx";
function Disponibilites({
  update,
  Prices,
  Slog,
  setPrices,
  CheckInAndOut,
  setCheckInAndOut,
  setClose,
  CalendrUID,
  AirbnbLink,
  fetchDataBienDetails,
  BookingLink,
  setBookingLink,
  setAirbnbLink,
}) {
  const [isCheckInOpen, setIsCheckInOpen] = useState();
  const [isCheckOutOpen, setIsCheckOutOpen] = useState();
  const [CheckInAndOutIsLoading, setCheckInAndOutIsLoading] = useState(false);
  const [DurationIsLoading, setDurationIsLoading] = useState(false);
  const [NewPrices, setNewPrices] = useState();
  const [NewCheckInAndOut, setNewCheckInAndOut] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    setNewPrices(Prices);
    setNewCheckInAndOut(CheckInAndOut);
  }, [Prices, CheckInAndOut]);

  const checkInRef = useRef();
  const checkOutRef = useRef();

  useClickOutside(checkInRef, () => setIsCheckInOpen(false));
  useClickOutside(checkOutRef, () => setIsCheckOutOpen(false));

  const { id } = useParams();
  async function UpdateCheckInAndOut() {
    setCheckInAndOutIsLoading(true);
    const userToken = Cookies.get("Token");
    const UserId = localStorage.getItem("ID_Utulisateur");
    try {
      const response = await axios.post(
        `https://visiontunisie.com/api/v1/GestionDesBiens/UpdateCheckInOut`,
        {
          Slog: Slog,
          ID_Proprietaire: UserId,
          CheckInTime: NewCheckInAndOut.checkIn,
          CheckOutTime: NewCheckInAndOut.checkOut,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setCheckInAndOutIsLoading(false);
      toast.success(
        t("Msgs.Updatesuccessful", {
          text:
            t("ReservationDetails.CheckIn") +
            " " +
            t("ReservationDetails.CheckOut"),
        })
      );
      setClose(false);
      return response?.data;
    } catch (error) {
      setCheckInAndOutIsLoading(false);
      throw new Error(`Error fetching geocoding data: ${error.message}`);
    }
  }

  async function Updatesejour() {
    setDurationIsLoading(true);
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    try {
      const response = await axios.post(
        `https://visiontunisie.com/api/v1/GestionDesBiens/UpdateDureeDeSejour`,
        {
          Slog: Slog,
          ID_Proprietaire: UserId,
          DureeMinimum: Prices.miniNights,
          DureeMaximum: Prices.maxNights,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setDurationIsLoading(false);
      toast.success(
        t("Msgs.Updatesuccessful", { text: t("Host.Prices.StayDuration") })
      );
      setClose(false);
      return response?.data;
    } catch (error) {
      setDurationIsLoading(false);
      throw new Error(`Error fetching geocoding data: ${error.message}`);
    }
  }

  const timeOptions = [
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
  ];

  const handleInputChange = (key, value) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [key]: value,
    }));
  };

  const generateTimes = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  };

  const times = generateTimes();

  return (
    <div className="w-full  items-start flex flex-col gap-5  pb-10 ">
      <h1 className="travelType text-lg font-semibold">
        {t("Host.Prices.StayDuration")}
      </h1>

      <div className="flex flex-col gap-5 w-full">
        <span>
          <label className=" mb-4 text-gray-500" htmlFor="">
            {" "}
            {t("Host.Prices.MinimumNumberOfNights")}:
          </label>
          <input
            value={NewPrices?.miniNights}
            onChange={(e) => handleInputChange("miniNights", e.target.value)}
            className="travelType w-full px-2 h-[3.2rem] border-[2px] rounded-lg"
            type="number"
            placeholder={t("Host.Prices.MinimumNumberOfNights")}
          />
        </span>
        <span>
          <label className=" mb-4 text-gray-500" htmlFor="">
            {t("Host.Prices.MaximumNumberOfNights")}:
          </label>
          <input
            value={NewPrices?.maxNights}
            onChange={(e) => handleInputChange("maxNights", e.target.value)}
            className="travelType w-full px-2 h-[3.2rem] border-[2px] rounded-lg"
            type="number"
            placeholder={t("Host.Prices.MaximumNumberOfNights")}
          />
        </span>
        <div className=" bottom-0 right-0  lg:border-0  border-t-[1px]  py-3 flex w-full justify-between gap-4  font-semibold ">
          <button
            disabled={DurationIsLoading}
            onClick={Updatesejour}
            className="  relative  w-full py-3 rounded-md   text-white bg-main "
          >
            {t("Actions.Save")}
            <LoadingBtn scale={"scale-[.15] "} isLoading={DurationIsLoading} />
          </button>
        </div>
      </div>
      <div className="   pt-5   w-full    flex  flex-col gap-5">
        <p className="travelType font-semibold">
          {t("Labels.CheckInTime")} , {t("Labels.CheckOutTime")}
        </p>
      </div>
      <div className="   pt-5   w-full   flex  flex-col gap-5">
        <div className="  gap-5   z-30 flex flex-row">
          <div className=" w-full ">
            <label className=" mb-1 text-gray-500" htmlFor="">
              {t("Labels.CheckInTime")}
            </label>
            <div
              ref={checkInRef}
              onClick={() => setIsCheckInOpen(!isCheckInOpen)}
              className="  flex items-center px-5 relative w-full h-[3.2rem] cursor-pointer text-main rounded-md border-[1px] "
            >
              <p>{NewCheckInAndOut?.checkIn}</p>

              {isCheckInOpen && (
                <span className=" shadow-lg rounded-lg flex flex-col overflow-auto absolute top-10 right-0 w-full h-[8rem] bg-white   ">
                  {timeOptions.map((time) => (
                    <span
                      className=" cursor-pointer px-3 py-2 hover:bg-slate-100 "
                      onClick={() => {
                        setCheckInAndOut({
                          ...NewCheckInAndOut,
                          checkIn: time,
                        });
                      }}
                    >
                      {" "}
                      {time}{" "}
                    </span>
                  ))}
                </span>
              )}
            </div>
          </div>

          <div className=" w-full">
            <label className=" mb-2 text-gray-500" htmlFor="">
              {t("Labels.CheckOutTime")}
            </label>
            <div
              ref={checkOutRef}
              onClick={() => setIsCheckOutOpen(!isCheckOutOpen)}
              className="  flex items-center px-5 relative w-full h-[3.2rem] cursor-pointer text-main rounded-md border-[1px] "
            >
              <p>{NewCheckInAndOut?.checkOut}</p>

              {isCheckOutOpen && (
                <span className=" shadow-lg rounded-lg flex flex-col overflow-auto absolute top-10 right-0 w-full h-[8rem] bg-white   ">
                  {timeOptions.map((time) => (
                    <span
                      className=" cursor-pointer px-3 py-2 hover:bg-slate-100 "
                      onClick={() => {
                        setCheckInAndOut({
                          ...NewCheckInAndOut,
                          checkOut: time,
                        });
                      }}
                    >
                      {time}
                    </span>
                  ))}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="  bottom-0 right-0  lg:border-0  border-t-[1px]  py-3 flex w-full justify-between gap-4  font-semibold ">
        <button
          disabled={CheckInAndOutIsLoading}
          onClick={UpdateCheckInAndOut}
          className="  relative w-full py-3 rounded-md   text-white bg-main "
        >
          {t("Actions.Save")}
          <LoadingBtn
            scale={"scale-[.15] "}
            isLoading={CheckInAndOutIsLoading}
          />
        </button>
      </div>
      {/* synchronisation des calendriers */}
      <SynchronisationCal
        Slog={Slog}
        CalendrUID={CalendrUID}
        AirbnbLink={AirbnbLink}
        BookingLink={BookingLink}
        setBookingLink={setBookingLink}
        setAirbnbLink={setAirbnbLink}
        fetchDataBienDetails={fetchDataBienDetails}
      />
    </div>
  );
}

export default Disponibilites;
