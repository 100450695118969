import { React, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Droppable } from "./droppable";
import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MobImage from "./MobImage";
import { UploadImg } from "../../API/UploadImg";
import LoadingBtn from "../ui/LoadingBtn";
import { useTranslation } from "react-i18next";
const AddImages = ({ Images, setImages ,editpage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getTaskPos = (id) => Images.findIndex((task) => task.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setImages((Images) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);

      return arrayMove(Images, originalPos, newPos);
    });
  };

  const addMoreImages = async (e) => {
    setIsLoading(true);
    
    try {
      const files = e.target.files;
      if (files?.length > 0) {
        const newTasks = [...Images];
        
        for (let i = 0; i < files?.length; i++) {
          const newId = Images?.length + i + 1;
          const imageUrl = await UploadImg(files[i]);
          newTasks.push({ id: newId, img: imageUrl });
        }
        
        setImages(newTasks);
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading images:", error);
      setIsLoading(false);
    }
  };
  
  const ValidImages = Images?.filter((image) => image.img !== null);

  const makeImageFirst = (id) => {
    // Clone the Images array to avoid mutating the state directly
    const updatedImages = [...Images];
    // Find the index of the image with the specified id
    const index = updatedImages.findIndex((img) => img.id === id);

    // If the image with the given id is found, move it to the first position
    if (index !== -1) {
      const selectedImage = updatedImages.splice(index, 1)[0];
      updatedImages.unshift(selectedImage);

      // Update the state with the new array order
      setImages(updatedImages);
    }
  };

  const handleDeleteImg = (id) => {
    // Filter out the image with the specified id
    const updatedImages = Images.filter((img) => img.id !== id);

    // Update the state with the new array without the deleted image
    setImages(updatedImages);
  };

  return (
    <div className=" text-left   pb-20  w-full   flex-col gap-7">
    {!editpage &&  <div className=" gap-5 flex flex-col">
        <h2 className=" flex flex-row gap-3  text-[1.5rem] md:text-[2rem] font-bold items-center  ">
          {t("Host.AddImages.AddImagesOfYourProperty")} 
        </h2>
        <p className="   text-gray-400   ">
        {t("Host.AddImages.AddMinimumImages")} 
        </p>
      </div>}
      <div className="  pt-3 hidden md:flex  flex-col  h-[50vh] w-full  ">
        <div className="App">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
          >
            <Droppable editpage={editpage} id="toDo" Images={Images} setImages={setImages} />
          </DndContext>
        </div>
      </div>

      {/* Mobile Veiw */}
      <div className=" md:hidden pt-3 flex">
        {ValidImages && (
          <div className="flex flex-col w-full  gap-3">
            {ValidImages[0]?.img && (
              <div className=" w-full relative  ">
                <span
                  onClick={() => handleDeleteImg(ValidImages[0].id)}
                  className={
                    " absolute top-3 right-3 bg-white text-black flex items-center justify-center rounded-[50%] w-[1.8rem] h-[1.8rem] "
                  }
                >
                  <CloseOutlinedIcon />
                </span>
                <img
                  className="w-full h-[10rem] object-cover"
                  src={
                    typeof ValidImages[0].img === "string"
                      ? ValidImages[0].img
                      : URL.createObjectURL(ValidImages[0].img)
                  }
                  alt="superhost"
                />
              </div>
            )}
            <div className="gap-3 grid grid-cols-2">
              {ValidImages.slice(1).map((img, index) => (
                <MobImage
                key={index}
                  makeImageFirst={makeImageFirst}
                  handleDeleteImg={handleDeleteImg}
                  id={img.id}
                  img={img.img}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className=" pt-5  w-full md:hidden flex flex-col ">
        <label
          htmlFor="addMoreImgMobe"
          className=" flex flex-row relative items-center px-4 justify-between w-full h-[4rem] rounded-lg border-solid border-2  border-sec  "
        >
          {isLoading ? (
            <LoadingBtn BgColor={"bg-transparent"} scale={"scale-[.15]"} color={'#FF5A5F'} isLoading={true} />
          ) : (
            <>
              {" "}
              {t("Host.AddImages.AddYourPhotos")} 
              <AddIcon className="  text-sec" />
            </>
          )}

         { !isLoading && <input
            className=" hidden"
            id="addMoreImgMobe"
            name="addMoreImgMobe"
            onChange={addMoreImages}
            type="file"
            accept="image/*"
            multiple={true}
          />}
        </label>
      </div>
    </div>
  );
};

export default AddImages;
