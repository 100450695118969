import axios from 'axios';

const getLatLngFromAddress = async (address, apiKey) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`
    );

    if (response.data.results.length > 0) {
      const result = response.data.results[0];
      return result
    } else {
      throw new Error('No results found');
    }
  } catch (error) {
    throw new Error(`Error fetching geocoding data: ${error.message}`);
  }
};

export default getLatLngFromAddress;
