import { proprse } from "../assest/ImgProposeData";

const getProps = (t) => [
  // Essential Equipment
  {
    group: "Essential",
    title: t("Host.Proposer.WIFI"),
    img: proprse.wifi,
    Active: proprse.wifiPink,
    value: "WIFI",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.fibreoptique"),
    img: proprse.fiber,
    Active: proprse.fiberPink,
    value: "fibreoptique",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.TV"),
    img: proprse.tv,
    Active: proprse.tvPink,
    value: "TV",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.chauffage"),
    img: proprse.chauffage,
    Active: proprse.chauffagePink,
    value: "chauffage",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.Climatisation"),
    img: proprse.climatiseur,
    Active: proprse.climatiseurPink,
    value: "Climatisation",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.Cuisine"),
    img: proprse.cuisine,
    Active: proprse.cuisinePink,
    value: "Cuisine",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.LaveLinge"),
    img: proprse.MachineLavee,
    Active: proprse.MachineLaveePink,
    value: "LaveLinge",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.lavevaisselle"),
    img: proprse.dishwasher,
    Active: proprse.dishwasherPink,
    value: "lavevaisselle",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.Lingedebain"),
    img: proprse.towels,
    Active: proprse.towelsPink,
    value: "LingeDebain",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.Literie"),
    img: proprse.bed,
    Active: proprse.bedPink,
    value: "Literie",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.eauChaude"),
    img: proprse.eauChaude,
    Active: proprse.eauChaudePink,
    value: "EauEhaude",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.ParkingPayant"),
    img: proprse.parkingPayant,
    Active: proprse.parkingPayantPink,
    value: "ParkingPayant",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.ParkingGratuit"),
    img: proprse.parking,
    Active: proprse.parkingPink,
    value: "ParkingGratuit",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.Detecteurdefumee"),
    img: proprse.detecteurFeux,
    Active: proprse.detecteurFeuxPink,
    value: "Detecteurdefumee",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.Extincteur"),
    img: proprse.Extinguisher,
    Active: proprse.ExtinguisherPink,
    value: "Extincteur",
  },
  {
    group: "Essential",
    title: t("Host.Proposer.CamerasDeSurveillance"),
    img: proprse.CamerasDeSurveillance,
    Active: proprse.CamerasDeSurveillancePink,
    value: "CamerasDeSurveillance",
  },

  {
    group: "Essential",
    title: t("Host.Proposer.gardien"),
    img: proprse.garde,
    Active: proprse.gardePink,
    value: "gardien",
  },

  //  Equipement en extra

  {
    group: "extra",
    title: t("Host.Proposer.Piscine"),
    img: proprse.pool,
    Active: proprse.poolPink,
    value: "Piscine",
  },
  {
    group: "extra",
    title: t("Host.Proposer.rooftop"),
    img: proprse.roof,
    Active: proprse.roofPink,
    value: "rooftop",
  },
  {
    group: "extra",
    title: t("Host.Proposer.EspaceDeTravail"),
    img: proprse.workspace,
    Active: proprse.workspacePink,
    value: "EspaceDeTravail",
  },
  {
    group: "extra",
    title: t("Host.Proposer.EspaceRepas"),
    img: proprse.lunchArea,
    Active: proprse.lunchAreaPink,
    value: "EspaceRepas",
  },

  {
    group: "extra",
    title: t("Host.Proposer.Patio"),
    img: proprse.patio,
    Active: proprse.patioPink,
    value: "Patio",
  },
  {
    group: "extra",
    title: t("Host.Proposer.Cheminee"),
    img: proprse.Cheminee,
    Active: proprse.ChemineePink,
    value: "Cheminee",
  },

  {
    group: "extra",
    title: t("Host.Proposer.baignoire"),
    img: proprse.Baignoire,
    Active: proprse.BaignoirePink,
    value: "baignoire",
  },
  {
    group: "extra",
    title: "jacuzzi",
    img: proprse.jacuzzi,
    Active: proprse.jacuzziPink,
    value: "Jacuzzi",
  },
  {
    group: "extra",
    title: t("Host.Proposer.jardin"),
    img: proprse.garden,
    Active: proprse.gardenPink,
    value: "jardin",
  },
  {
    group: "extra",
    title: t("Host.Proposer.Barbecue"),
    img: proprse.Barbecue,
    Active: proprse.BarbecuePink,
    value: "Barbecue",
  },

  {
    group: "extra",
    title: t("Host.Proposer.AppareilsDeFitness"),
    img: proprse.Fitness,
    Active: proprse.FitnessPink,
    value: "AppareilsDeFitness",
  },
  {
    group: "extra",
    title: t("Host.Proposer.livres"),
    img: proprse.books,
    Active: proprse.booksPink,
    value: "livres",
  },
  {
    group: "extra",
    title: t("Host.Proposer.AccesAuLac"),
    img: proprse.AccesAuLac,
    Active: proprse.AccesAuLacPink,
    value: "AccesAuLac",
  },
  {
    group: "extra",
    title: t("Host.Proposer.DoucheExterieure"),
    img: proprse.doucheExterieur,
    Active: proprse.doucheExterieurPink,
    value: "DoucheExterieure",
  },
  {
    group: "extra",
    title: t("Host.Proposer.espacerepasexterieur"),
    img: proprse.spaceOutDoor,
    Active: proprse.spaceOutDoorPink,
    value: "espacerepasexterieur",
  },
  {
    group: "extra",
    title: t("Host.Proposer.jeux"),
    img: proprse.game,
    Active: proprse.gamePink,
    value: "jeux",
  },
  {
    group: "extra",
    title: t("Host.Proposer.Brasero"),
    img: proprse.Brasero,
    Active: proprse.BraseroPink,
    value: "Brasero",
  },

  {
    group: "extra",
    title: t("Host.Proposer.Billard"),
    img: proprse.billard,
    Active: proprse.billardPink,
    value: "Billard",
  },

  {
    group: "extra",
    title: t("Host.Proposer.Piano"),
    img: proprse.piano,
    Active: proprse.pianoPink,
    value: "Piano",
  },

  {
    group: "extra",
    title: t("Host.Proposer.KitDePremiersSecours"),
    img: proprse.aidKit,
    Active: proprse.aidKitPink,
    value: "KitDePremiersSecours",
  },
  {
    group: "extra",
    title: t("Host.Proposer.DetecteurCo2"),
    img: proprse.detecteurCo2,
    Active: proprse.detecteurCo2Pink,
    value: "DetecteurCo2",
  },


  // Caractéristiques

  {
    group: "détente",
    title: t("Host.Proposer.quartierCentral"),
    img: proprse.centerQ,
    Active: proprse.centerQPink,
    value: "quartierCentral",
  },
  {
    group: "détente",
    title: t("Host.Proposer.quartierCalme"),
    img: proprse.calme,
    Active: proprse.calmePink,
    value: "quartierCalme",
  },
  {
    group: "détente",
    title: t("Host.Proposer.quartiercommercant"),
    img: proprse.downtown,
    Active: proprse.downtownPink,
    value: "quartiercommercant",
  },

  {
    group: "détente",
    title: t("Host.Proposer.Paisible"),
    img: proprse.Peaceful,
    Active: proprse.PeacefulPink,
    value: "Paisible",
  },

  {
    group: "détente",
    title: t("Host.Proposer.Spacieux"),
    img: proprse.Spacieux,
    Active: proprse.SpacieuxPink,
    value: "Spacieux",
  },
 {
    group: "détente",
    title: t("Host.Proposer.BienUnique"),
    img: proprse.bienUnique,
    Active: proprse.bienUniquePink,
    value: "BienUnique",
  },
  {
    group: "détente",
    title: t("Host.Proposer.auborddelamer"),
    img: proprse.NearToSea,
    Active: proprse.NearToSeaPink,
    value: "auborddelamer",
  },
  {
    group: "détente",
    title: t("Host.Proposer.vuemer"),
    img: proprse.sea,
    Active: proprse.seaPink,
    value: "vuemer",
  },
  {
    group: "détente",
    title: t("Host.Proposer.Restaurantsetcafes"),
    img: proprse.coffee,
    Active: proprse.coffeePink,
    value: "Restaurantsetcafes",
  },
  {
    group: "détente",
    title: t("Host.Proposer.Transportsencommun"),
    img: proprse.transport,
    Active: proprse.transportPink,
    value: "Transportsencommun",
  },
  {
    group: "détente",
    title: t("Host.Proposer.archéologiques"),
    img: proprse.Monument,
    Active: proprse.MonumentPink,
    value: "archeologiques",
  },

  {
    group: "détente",
    title: t("Host.Proposer.Petitdejeunerdisponible"),
    img: proprse.breakfast,
    Active: proprse.breakfastPink,
    value: "Petitdejeunerdisponible",
  },

  {
    group: "détente",
    title: t("Host.Proposer.Central"),
    img: proprse.Central,
    Active: proprse.CentralPink,
    value: "Central",
  },




 
  
 
];

export default getProps;
