import Apple from "./Apple Logo.svg";
import Facebook from "./Facebook Logo.svg";
import Google from "./Google Logo.svg";
import loading from "./loading.svg";
import login from "./login.png";
import signUp from "./signUp.png";
import changePassImg from "./changePass.png";
import sendEmail from "./sendEmail.png";
export const SharedImg = {
  Apple,
  Facebook,
  Google,
  loading,
  login:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/585a9095-98aa-426a-4362-b0cd496acd00/public",
  signUp : "https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/07db22d4-d4f9-41f7-a1f5-2bde8a909600/public",
  changePassImg:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/cfd435c0-6259-4505-2915-0f6f20e02b00/public",
  sendEmail:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/222ea362-2be6-461f-3b9b-7a5d9d3f8700/public",
};
