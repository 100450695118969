import Apartement from "./apartment.svg";
import ApartementPink from "./apartmentPink.svg";
import Vila from "./Villa.svg";
import VilaPink from "./VillaPink.svg";
import Chambre from "./Chambre.svg";
import ChambrePink from "./ChambrePink.svg";
import Chalet from "./Chalet.svg";
import ChaletPink from "./ChaletPink.svg";
import decouvrir from "./Ldecouvrir.png";
import LMamuser from "./LM’amuser.png";
import detendre from "./Ldetendre.png";
import travaille from "./Ltravaille.png";



export const PropertyImg = {
  Apartement,
  ApartementPink,
  Vila,
  VilaPink,
  Chambre,
  ChambrePink,
  Chalet,
  ChaletPink,
};

export const TravelType = {
  decouvrir:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/15220118-2be6-4b72-8200-60743f76c900/public",
  LMamuser:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/51549b63-1386-4d2d-090e-95c736c0b200/public",
  detendre:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/519afc89-5861-4865-82c9-570c8a0d8c00/public",
  travaille:"https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/baf5d833-aac0-45a5-038e-dfd4d9aed500/public"
}
