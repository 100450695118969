import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DraggableImage from "./FileDropZone";
import FileDropZone from "./FileDropZone";
import LoadingBtn from "../../component/ui/LoadingBtn";
import CloseIcon from "@mui/icons-material/Close";
import { UploadImg } from "../../API/UploadImg";
import { useState } from "react";
export const DraggableImg = ({ id, img, index, setImages }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const [isLoading, setIsLoading] = useState(false);
  const [fileImg, setfileImg] = useState(null);

  if (!img && index > 4) return;

  const handleFileDrop = async (files) => {
    setIsLoading(true);
    setfileImg(files);
    try {
      // Assuming you want to upload each dropped image separately
      const imageUrls = await Promise.all(
        files.map(async (file) => await UploadImg(file))
      );

      const successfulImageUrls = imageUrls.filter(
        (url) => url !== null && url !== undefined
      );
      // Update the Images state with the received URLs and incrementing IDs
      setImages((prevImages) => {
        const newImages = [
          ...prevImages, // Keep the previous images
          ...successfulImageUrls.map((url, index) => ({
            id: prevImages.length + index + 1,
            img: url,
          })),
        ];

        // Sort the array with null values at the end
        const sortedImages = newImages.sort((a, b) => {
          if (a.img === null && b.img !== null) return 1;
          if (a.img !== null && b.img === null) return - 1;
          return 0;
        });

        return sortedImages;
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle error if needed
    }
  };

  const handleDeleteImg = (index) => {
    setImages((prevImages) => {
      if (index > 4) {
        // Remove the image with index greater than 4
        const filteredImages = prevImages.filter((_, i) => i !== index);
        return filteredImages;
      } else {
        // Check if the image at the specified index is not null before updating
        if (prevImages[index] && prevImages[index].img !== null) {
          const updatedImages = [...prevImages];
          updatedImages[index] = { img: null };

          // Move null images to the end
          const sortedImages = updatedImages.sort((a, b) => {
            if (a.img === null && b.img !== null) return 1;
            if (a.img !== null && b.img === null) return - 1;
            return 0;
          });

          return sortedImages;
        }
      }
      // Return the original array if no changes were made
      return prevImages;
    });
  };

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div className=" bg-white images w-full h-full relative">
      {img && (
        <span
          onClick={() => handleDeleteImg(index)}
          className=" text-white cursor-pointer  z-[70] absolute flex items-center justify-center top-4 right-4 rounded-full w-[2rem] h-[2rem] bg-black bg-opacity-30 "
        >
          <CloseIcon />
        </span>
      )}
      {!img ? (
        isLoading ? (
          <div className="   flex items-center justify-center">
            <img
              className=" w-full h-full absolute top-0 right-0  object-contain  opacity-25 "
              src={fileImg ? URL.createObjectURL(fileImg[0]) : null}
              alt="superhost"
            />
            <LoadingBtn BgColor={"bg-transparent"} isLoading={true} />
          </div>
        ) : (
          <FileDropZone index={index} onFileDrop={handleFileDrop} />
        )
      ) : (
        <div
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          className="task"
        >
          <img
            style={{
              touchAction: "none",
            }}
            className={` ${
              index == 0
                ? "  h-[15rem] md:h-[55vh] object-cover "
                : "h-[15rem]  md:h-[27vh] object-contain "
            } w-full   border-solid border-2 `}
            src={img}
            alt="superhost"
          />
        </div>
      )}
    </div>
  );
};
