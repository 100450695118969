// FileDropZone.js
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import noImage from "../../assest/img/noImage.png";
import { useTranslation } from "react-i18next";
const FileDropZone = ({ onFileDrop, index }) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (onFileDrop) {
        onFileDrop(acceptedFiles);
      }
    },
    [onFileDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      onClick={(e) => e.preventDefault()}
      className={` w-full border-solid border-[2px]  border-gray-200  ${
        index == 0
          ? "  h-[15rem] md:h-[55vh] object-cover "
          : "h-[15rem]  md:h-[27vh] object-contain "
      }`}
      {...getRootProps()}
      style={dropzoneStyle}
    >
      <input accept="image/*" {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here...</p>
      ) : (
        <div
          className={`  h-full w-full  justify-center flex flex-col gap-3 items-center`}
        >
          <img className={` w-[20%] `} src={noImage} alt="noImage" />
          {index == 0 && (
            <p className=" font-semibold">
              {" "}
              {t("Host.AddImages.YouCan")}{" "}
              <span className=" text-sec">{t("Actions.Import")}</span>{" "}
              {t("Host.AddImages.Or")}
              <span className=" text-sec">{t("Actions.DragAndDrop")}</span>{" "}
              {t("Host.AddImages.YourStampHeree")}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const dropzoneStyle = {
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default FileDropZone;
