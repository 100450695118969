import { React, useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import { fetchWithBody } from "./fetchWithBody"; // Custom fetch function (explained below)
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function PropertyInfoUpdate({ numChombreAndList, setNumChombreAndList, Slog }) {
  const [isLoading, setIsLoading] = useState(null); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling

  const [NewnumChombreAndList, setNewNumChombreAndList] = useState({
    NbVoyageurs: 1,
    NbChambres: 0,
    NbLits: 0,
    NbSallesDeBain: 1,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setNewNumChombreAndList({
      NbVoyageurs: numChombreAndList.NbVoyageurs,
      NbChambres: numChombreAndList?.NbChambres,
      NbLits: numChombreAndList?.NbLits,
      NbSallesDeBain: numChombreAndList?.NbSallesDeBain,
    });
  }, [numChombreAndList]);

  const Items = [
    {
      key: "NbVoyageurs",
      label: t("HostMode.placeDetails.MaxGuests"),
      endPint: "/GestionDesBiens/UpdateNbVoyageurs",
    },
    {
      key: "NbChambres",
      label: t("HostMode.placeDetails.NumBedrooms"),
      endPint: "/GestionDesBiens/UpdateNbChambres",
    },
    {
      key: "NbLits",
      label: t("HostMode.placeDetails.NumBeds"),
      endPint: "/GestionDesBiens/UpdateNbLits",
    },
    {
      key: "NbSallesDeBain",
      label: t("HostMode.placeDetails.NumBathrooms"),
      endPint: "/GestionDesBiens/UpdateNbSallesDeBain",
    },
  ];

  const handleDecrement = (type, endPint) => {
    if (NewnumChombreAndList[type] > 0) {
      setNewNumChombreAndList((prevCounts) => ({
        ...prevCounts,
        [type]: prevCounts[type] - 1,
      }));
      updatePropertyInfo(type, NewnumChombreAndList[type] - 1, endPint);
    }
  };

  const handleIncrement = (type, endPint) => {
    setNewNumChombreAndList((prevCounts) => ({
      ...prevCounts,
      [type]: prevCounts[type] + 1,
    }));
    updatePropertyInfo(type, NewnumChombreAndList[type] + 1, endPint);
  };

  const { id } = useParams();

  const updatePropertyInfo = async (type, value, endPint) => {
    setIsLoading(type); // Set loading state
    setError(null); // Clear any previous errors
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    const endpoint = `https://visiontunisie.com/api/v1${endPint}`;
    const body = {
      Slog: Slog,
      ID_Proprietaire: UserId,
      [`${type}`]: value,
    };
    try {
      const response = await axios.post(endpoint, body, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }); // Use axios.put for PUT requests
      if (!response.data.success) {
        // Check for success in response data (assuming API response structure)
        throw new Error(response.data.message || "API request failed");
      }
      setNumChombreAndList({
        ...numChombreAndList,
        [type]: value,
      });
      setIsLoading(null);
      toast.success(t("Msgs.Updatesuccessful"));
    } catch (err) {
      setError(err.message);
      console.error("Error updating property information:", err);
    } finally {
      setIsLoading(null);
    }
  };

  //   useEffect(() => {
  //     // Optional: Pre-fetch data on component mount (if needed)
  //   }, []);

  return (
    <div className=" w-full flex flex-col gap-10 ">
      <div className=" flex flex-col gap-5  w-full lg:w-[50%]  ">
        {Items.map((item) => (
          <span className=" info flex font-semibold flex-row items-center justify-between  w-full  py-3 px-5 border-solid border-[1px] rounded-md ">
            <h1> {item.label} </h1>
            <div
              className={`  ${
                isLoading == item?.key ? " opacity-50" : " "
              }   flex h-full p-2 rounded gap-3  text-[1.1rem]   flex-row`}
            >
              <button
                disabled={isLoading == item.key}
                onClick={() => handleDecrement(item.key, item.endPint)}
                className={`    h-8 w-8 active:scale-95  text-sec rounded  border-main border-[1px] border-opacity-20 bg-opacity-20`}
              >
                <RemoveIcon fontSize="" />
              </button>
              <span>{NewnumChombreAndList[item.key]}</span>
              <button
                disabled={isLoading == item.key}
                onClick={() => handleIncrement(item.key, item.endPint)}
                className={`   h-8 w-8 active:scale-95  text-sec rounded  border-main border-[1px] border-opacity-20 bg-opacity-20`}
              >
                <AddIcon fontSize="" />
              </button>
            </div>
          </span>
        ))}
      </div>
    </div>
  );
}

export default PropertyInfoUpdate;
