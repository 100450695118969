import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import LoadingBtn from "../../ui/LoadingBtn";



function UpdatePrice({ isOpen, setIsOpen, Prix, setPrices , Slog }) {
  const [NewPrice, setNewPrice] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNewPrice(Prix);
  }, [Prix, isOpen]);

  const { t } = useTranslation();

  const { id } = useParams();

  async function UpdatePrice() {
    setIsLoading(true);
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    try {
      const response = await axios.post(
        `https://visiontunisie.com/api/v1/GestionDesBiens/UpdatePrix`,
        {
          Slog: Slog,
          ID_Proprietaire: UserId,
          Prix: NewPrice,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      toast.success(t("Msgs.Updatesuccessful", { text: t('Labels.PricePerNight')}));
      setIsLoading(false);
      setIsOpen(false);
      setPrices((prevPrices) => ({
        ...prevPrices,
        Prix: NewPrice,
      }));
      return response?.data;
    } catch (error) {
      setIsLoading(false);
      throw new Error(`Error fetching geocoding data: ${error.message}`);
    }
  }

  return (
    <div
      className={` bg-white top-0 duration-200 w-full h-full  fixed z-[70] lg:absolute ${
        isOpen ? " right-[0%] " : " -right-[100%]"
      } `}
    >
      <div className=" flex flex-col gap-4 pt-[3rem] w-full px-5">
        <span className=" relative">
          <label
            className=" absolute top-1 left-5 font-medium text-sm "
            htmlFor=""
          >
            {t("Labels.PerNight")}
          </label>
          <input
            onChange={(e) => setNewPrice(e.target.value)}
            value={NewPrice}
            className=" w-full h-[5.5rem] text-[4rem]  font-bold px-4 border-2  rounded-xl "
            type="number"
          />
        </span>

        <div className="   lg:relative absolute bottom-0 right-0  lg:border-0  lg:px-0 px-4 border-t-[1px]  py-3 flex w-full justify-between gap-4  font-semibold ">
          <button
            onClick={() => setIsOpen(false)}
            className="   w-full py-3 rounded-md  text-gray-400 border-2 "
          >
            {t("Actions.Cancel")}
          </button>
          <button
            onClick={UpdatePrice}
            className="  relative  w-full py-3 rounded-md   text-white bg-main "
          >
            {t("Actions.Save")}
            <LoadingBtn isLoading={isLoading}  />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdatePrice;
