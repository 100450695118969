import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useClickOutside } from "../../hooks/UseClickOutSide";
import { useTranslation } from "react-i18next";

const DropdownWithSearch = ({
  Places,
  SelectedPlace,
  setSelectedPlace,
  id,
}) => {
  const [IsDropDownOpen, setIsDropDownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isInputVisible, setIsInputVisible] = useState(false);
  const DropDownRef = useRef(null);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useClickOutside(DropDownRef, () => {
    setIsDropDownOpen(false);
  });

  useEffect(() => {
    const Selectedplace = Places?.find((place) => place?.Slog == id);
    setSelectedPlace({
      title: Selectedplace?.Titre,
      img: Selectedplace?.UrlImage,
    });
  }, [id, Places]);

  const filteredPlaces = Places?.filter((place) =>
    place.Titre.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
 

  useEffect(() => {
    if (!IsDropDownOpen) return;
    setIsInputVisible(true);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear the input value
      inputRef.current.focus();
    }
    setSearchQuery("");
  }, [IsDropDownOpen , isInputVisible]);


 
  useEffect(() => {
    if(IsDropDownOpen) return
    setSearchQuery(SelectedPlace?.title);
    if (isInputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputVisible, SelectedPlace , IsDropDownOpen]);

  useEffect(() => {
    if (!IsDropDownOpen && isInputVisible) {
      setIsDropDownOpen(true);
    }
  }, [searchQuery]);
 
  useEffect(() => {
    if (IsDropDownOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [IsDropDownOpen]);



  return (
    <div
      ref={DropDownRef}
      onClick={(e) => setIsDropDownOpen(!IsDropDownOpen)}
      className={` rounded-full border-[1px] items-center w-[14rem] lg:w-[16rem] justify-between  ${
        IsDropDownOpen && "border-sec"
      }   flex relative py-2 px-3`}
    >
      <span className="flex gap-2 items-center" >
        <img
          height={32}
          width={32}
          loading={"lazy"}
          className="w-[2rem]  rounded-full object-cover h-[2rem]"
          src={SelectedPlace?.img}
          alt="SelectedPlace"
        />
        {isInputVisible ? (
          <input
            ref={inputRef}
            type="text"
            // onClick={(e)=>}
              onFocus={()=> setIsInputVisible(true)}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t("Actions.Search")}
            className=" outline-none  border-b-[1px] px-1 borderopa  py-2  ring-opacity-0 h-full w-full border-none"
          />
        ) : (
          <span onClick={() => setIsInputVisible(true)}>
            {SelectedPlace?.title?.length > 10
              ? SelectedPlace?.title?.slice(0, 10) + "..."
              : SelectedPlace?.title}
          </span>
        )}
      </span>
      <KeyboardArrowDownIcon
        className={`${IsDropDownOpen ? "rotate-180" : " "}`}
      />
      <div
        className={`overflow-auto flex flex-col  absolute top-[3.3rem] max-h-[15rem] right-0 w-full bg-white ${
          IsDropDownOpen ? "flex" : "hidden"
        } duration-200 shadow-lg z-30 rounded-md`}
      >
        {filteredPlaces?.map((place) => (
          <Link
          rel="canonical"
            key={place.Slog}
            onClick={() => {
              setSelectedPlace({
                title: place.Titre,
                img: place.UrlImage,
              });
              setIsInputVisible(false);
              setIsDropDownOpen(false);
            }}
            to={`/dashboard/calendrier/${place.Slog}`}
            className={`${
              place?.Slog == id ? "bg-gray-100" : "bg-white"
            } hover:bg-gray-50 border-b-[1px] flex flex-row items-center gap-3 p-2`}
          >
            <img
            height={32}
            width={32}
            loading={"lazy"}
              className="w-[2rem] h-[2rem] object-cover rounded-full"
              src={place.UrlImage}
              alt="place"
            />
            {place?.Titre?.length > 15
              ? place?.Titre?.slice(0, 15) + "..."
              : place?.Titre}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DropdownWithSearch;
