// Button.js

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({ onClick, label, type, disabled, BGcolor, hoverColor ,Linkto }) => {
  return (
    <Link 
    to={Linkto}
       className={` ${BGcolor} border-solid hover:bg-transparent  duration-100   md:text-base text-sm    px-[1rem] md:px-[2rem]    py-[.5rem]    md:py-[.75rem]    ,
      border-2 ${
        BGcolor
          ? `text-white  border-[#172A4C]  hover:text-[#172A4C] `
          : " border-transparent"
      }
        
        
        `}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{
        margin: "5px",
        cursor: "pointer",
        borderRadius: "0.375rem",
      }}
    >
      {label}
    </Link>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  disabled: false,
};

export default Button;
