// DateSelectionUtils.js

const toggleSelection = (selectedDates, ReservationErevts, setSelectedSlots, isPastDate) => {
    // Convert selectedDates to an array if it's not already an array
    const datesArray = Array.isArray(selectedDates)
      ? selectedDates
      : [selectedDates];
  
    // Filter out any dates before today
    const validDatesArray = datesArray.filter((date) => !isPastDate(date));
  
    // Check if any of the valid dates have events
    const hasEventOnSelectedDates = validDatesArray.some((date) =>
      ReservationErevts?.some((reservation) => {
        const reservationStartDate = new Date(reservation.start);
        const reservationEndDate = new Date(reservation.end);
        const selectedDate = new Date(date);
        return (
          selectedDate >= reservationStartDate &&
          selectedDate < reservationEndDate
        );
      })
    );
  
    if (!hasEventOnSelectedDates) {
      // Allow selection if there are no events on valid dates
      setSelectedSlots((prevSelected) => {
        // Check if all valid dates are already in the array
        const shouldRemoveAll = validDatesArray.every((date) =>
          prevSelected.includes(date)
        );
  
        if (shouldRemoveAll) {
          // Remove all valid dates if they are already present
          return prevSelected.filter((date) => !validDatesArray.includes(date));
        } else {
          // Add the valid dates if any of them is not present
          return [...prevSelected, ...validDatesArray];
        }
      });
    } else {
      // Handle case when there are events on selected dates if needed
    }
  };
  
  export default toggleSelection;
  