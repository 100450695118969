import React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const CustomToolbar = (props) => {
  const goToBack = () => {
    props.onNavigate('PREV');
  };

  const goToNext = () => {
    props.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    props.onNavigate('TODAY');
  };

  const label = () => {
    const date = props.date;
    const locale = props.localizer.locale;
    const month = date.toLocaleString(locale, { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className="rbc-toolbar w-full flex gap-5 ">
      <span style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} className="rbc-btn-group flex   ">
        {/* <button type="button" onClick={goToBack}>Back</button> */}
        <button  
        style={{
            borderRadius: "50%",
            borderColor: '#FF5A5F',
            height: '2.5rem',
            width: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        className=' border-[1px]  bg-sec font-semibold rounded-[50%] px-2  ' onClick={goToBack}>  <NavigateBeforeIcon className='text-sec'/> </button>
      <span className="rbc-toolbar-label">{label()}</span>

        <button  
         style={{
            borderRadius: "50%",
            borderColor: '#FF5A5F',
            height: '2.5rem',
            width: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        className=' border-[1px]  bg-sec font-semibold rounded-[50%] px-2  ' onClick={goToNext}><NavigateBeforeIcon className=' text-sec rotate-180 ' /></button>
      </span>
    </div>
  );
};

export default CustomToolbar;
