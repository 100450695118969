import { React, useEffect, useState } from "react";
import gsap from "gsap";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const urlSchema = z.object({
  url: z.string().url({ message: "Invalid URL" }),
});

const UrlForm = ({ onSubmit, NewLink, t, CloseState, Label, DeletLink }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(urlSchema),
  });

  useEffect(() => {
    if (NewLink) {
      setValue("url", NewLink);
    }
  }, [NewLink]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" bg-white shadow-lg  border-[1px]  rounded-md flex flex-col gap-3 p-3">
        <div className="inputs flex flex-col  gap-1">
          <p>{Label}</p>
          <div className=" w-full flex   items-center flex-row gap-2 justify-end ">
            <input
              // value={NewLink}
              className={` border ${
                errors.url ? "border-red-500" : "border-gray-300"
              }   focus:outline-none focus:border-main w-full px-2 h-[3.4rem] border-[2px] rounded-lg`}
              id="url"
              type="text"
              {...register("url")}
              placeholder={"Booking Airbnb Link"}
            />
            <span
              onClick={DeletLink}
              className="  cursor-pointer px-4  py-3 rounded-md border-red-400  border-[1px] text-red-500"
            >
              <DeleteOutlinedIcon className=" scale-110" />
            </span>
          </div>
          {errors.url && (
            <span className=" text-sm px-1  text-red-500">
              {errors.url.message}
            </span>
          )}
        </div>
        <div className=" border-t-[1px] pt-2 flex flex-row justify-between">
          <button
            onClick={() => CloseState(false)}
            className="  px-3 p-2   text-gray-400 border-[1px] rounded-md font-medium "
          >
            {t("Actions.Cancel")}
          </button>
          <button
            type="submit"
            className="  px-3 p-2 bg-sec text-white rounded-md font-medium "
          >
            {t("Actions.Save")}
          </button>
        </div>
      </div>
    </form>
  );
};

function UpdateAirbnbLinks({ Links, setLinks , Slog }) {
  const [EditURLbooking, setEditURLbooking] = useState(null);
  const [EditURLairBnb, setEditURLairBnb] = useState(null);
  const [NewLinks, setNewLinks] = useState({
    Link01: "",
    Link02: "",
  });

  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    setNewLinks({
      Link01: Links.Link01,
      Link02: Links.Link02,
    });
  }, [Links]);

  function UpdateURLairBnb(data) {
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    const updateTitreData = {
      Slog: Slog,
      URLairBnb: data.url,
      ID_Proprietaire: UserId,
    };
    axios
      .post(
        "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateURLairBnb",
        updateTitreData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setEditURLairBnb(false);
        setLinks({
          ...Links,
          Link01: data.url,
        });
        toast.success(
          t("Msgs.Updatesuccessful", {
            text: `Airbnb.com ${t("Host.AddAirbnbLink.CalenderLink")}`,
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function UpdateURLbooking(data) {
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    const updateTitreData = {
      Slog: Slog,
      URLbooking: data.url,
      ID_Proprietaire: UserId,
    };

    axios
      .post(
        "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateURLbooking",
        updateTitreData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        // Handle the response data here
        setEditURLbooking(false);
        setLinks({
          ...Links,
          Link02: data.url,
        });
        toast.success(
          t("Msgs.Updatesuccessful", {
            text: `Booking.com ${t("Host.AddAirbnbLink.CalenderLink")}`,
          })
        );
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  }

  async function handeleDeletURLbooking() {
    try {
      const data ={
        url:''
      }
      const res = await UpdateURLbooking(data)
      setNewLinks({
        ...Links,
        Link02: "",
      });
     
    } catch (error) {
      
    }
  }

  async function handeleDeletURLAirbnb() {
    try {
      const data ={
        url:''
      }
      const res = await UpdateURLairBnb(data)
      setNewLinks({
        ...Links,
        Link01: "",
      });
      
    } catch (error) {
      
    }
  }

  const Style = {
    wordWrap: "break-word",
    overflowWrap: "break-word",
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div className={` flex flex-col h-full  lg:w-[50%] w-full gap-10`}>
      <div className="inputs flex flex-col  w-full gap-3">
        <h1 className="  w-full font-semibold text-lg">
          {`Booking.com ${t("Host.AddAirbnbLink.CalenderLink")}`}
        </h1>
        {!EditURLbooking ? (
          <div className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
            <div className=" flex flex-col gap-2">
              <h2 className="">
                {" "}
                {!Links?.Link02 ? (
                  <span className=" text-gray-500 w-full text-center  ">
                    -- not provided yet --
                  </span>
                ) : Links?.Link02?.length > 50 ? (
                  Links?.Link02?.slice(0, 50) + "..."
                ) : (
                  Links?.Link02 || <Skeleton />
                )}{" "}
              </h2>
            </div>
            <button
              className="font-semibold  cursor-pointer text-sec"
              onClick={() => setEditURLbooking(true)}
            >
              {t("Actions.Edit")}
            </button>
          </div>
        ) : (
          <div className="w-full  gap-6 flex flex-col">
            <UrlForm
              t={t}
              DeletLink={handeleDeletURLbooking}
              NewLink={NewLinks?.Link02}
              Label={
                t("Host.AddAirbnbLink.GiveCalender") +
                "," +
                t("Host.AddAirbnbLink.YourPostIsPending")
              }
              CloseState={setEditURLbooking}
              onSubmit={UpdateURLbooking}
            />
          </div>
        )}
      </div>
 
      {/* Calender Airbnb Link */}
      <div className="inputs flex flex-col  w-full gap-3">
        <h1 className=" font-semibold text-lg">
          {`Airbnb.com ${t("Host.AddAirbnbLink.CalenderLink")}`}
        </h1>
        {!EditURLairBnb ? (
          <div className="   flex-col lg:flex-row  gap-2 w-full flex  items-start lg:items-center justify-between ">
            <div className=" flex flex-col text-ellipsis gap-2">
              <h2 className="">
                {" "}
                {!Links?.Link01 ? (
                  <span className=" text-gray-500  ">
                    -- not provided yet --
                  </span>
                ) : Links?.Link01?.length > 40 ? (
                  Links?.Link01?.slice(0, 40) + "..."
                ) : (
                  Links?.Link01 || <Skeleton />
                )}{" "}
              </h2>
            </div>
            <button
              className="font-semibold  cursor-pointer text-sec"
              onClick={() => setEditURLairBnb(true)}
            >
              {t("Actions.Edit")}
            </button>
          </div>
        ) : (
          <div className="w-full   gap-6 flex flex-col">
            <UrlForm
              t={t}
              DeletLink={handeleDeletURLAirbnb}
              NewLink={NewLinks?.Link01}
              Label={
                t("Host.AddAirbnbLink.GiveBooking") +
                "," +
                t("Host.AddAirbnbLink.YourPostIsPending")
              }
              CloseState={setEditURLairBnb}
              onSubmit={UpdateURLairBnb}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateAirbnbLinks;
