import { React, useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from "./Calender/ToolBar.jsx";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import GrayLine from "../ui/GrayLine.jsx";
import Tarifs from "../../component/Dashboard/Tarifs";
import Disponibilites from "../../component/Dashboard/Disponibilites.jsx";
import DropdownWithSearch from "../../component/Dashboard/DropdownWithSearch.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import UpdateSelectedDatesRange from "./Calender/UpdateSelectedDatesRange.jsx";
import MiniHouse from "../../assest/img/MiniHoseSelected.svg";
import Topbar from "../ui/Topbar.jsx";
import { useTranslation } from "react-i18next";
import ReservationsDetails from "./Reservations/ReservationsDetails";
import CloseIcon from "@mui/icons-material/Close";
import { useClickOutside } from "../../hooks/UseClickOutSide.js";
import Cookies from "js-cookie";
import "react-loading-skeleton/dist/skeleton.css";

import "moment/locale/es"; // For Spanish
import "moment/locale/fr"; // For French
import Skeleton from "react-loading-skeleton";
import SeoHelmet from "../../seo/SeoHelmet.jsx";
import getBeinByUserId from "../../API/getBeinByUserId.js";
import Navbar from "../Navbar.jsx";
import MobileMenuBar from "../MobileMenuBar.jsx";
import CustomDateCellWrapper from "./Calender/CustomDateCellWrapper.jsx";
import EventRender from "./Calender/EventRender.jsx";
import toggleSelection from "../../Functions/CanderToggelSelection.js";
// import '../../App.js'

function Calendrier() {
  const { t, i18n } = useTranslation();

  // Set the locale for moment based on the current language
  moment.locale(i18n.language);
  const localizer = momentLocalizer(moment);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [Places, setPlaces] = useState();

  const [tarifs, setTarifs] = useState(true);
  const [SelectedPlace, setSelectedPlace] = useState("");
  const [Reservation, setReservation] = useState(null);
  const [BlokedDates, setBlokedDates] = useState([]);
  const [ResDetailsId, setResDetailsId] = useState(null);
  const [IsEditOpen, setIsEditOpen] = useState(false);
  const [IsselectBeinLoading, setIsselectBeinLoading] = useState(false);
  const [DataOfAirbnb, setIcsDataOfAirbnb] = useState(null);
  const [Slog, setSlog] = useState(null);
  const [DataOfBooking, setIcsDataOfBooking] = useState(null);
  const [CheckInAndOut, setCheckInAndOut] = useState({
    checkIn: null,
    checkOut: null,
  });
  const messages = {
    allDay: t("Calender.allDay"),
    previous: t("Calender.previous"),
    next: t("Calender.next"),
    today: t("Calender.today"),
    month: t("Calender.month"),
    week: t("Calender.week"),
    day: t("Calender.day"),
    agenda: t("Calender.agenda"),
    date: t("Calender.date"),
    time: t("Calender.time"),
    event: t("Calender.event"),
    noEventsInRange: t("Calender.noEventsInRange"),
    showMore: (total) => t("Calender.showMore", { total }),
  };

  const { id } = useParams();

  const [NewPrices, setNewPrices] = useState([]);
  const [ID_Bien, setID_Bien] = useState();

  const [ReservationErevts, setReservationErevts] = useState();

  const [Reductions, setReductions] = useState({
    re1: {
      value: 20,
      isChecked: false,
    },
    re2: {
      value: 20,
      isChecked: false,
    },
  });
  const [Prices, setPrices] = useState({
    menage: null,
    maxNights: null,
    miniNights: null,
    Prix: null,
  });

  const [BookingLink, setBookingLink] = useState();
  const [AirbnbLink, setAirbnbLink] = useState();
  const [CalendrUID, setCalendrUID] = useState();
  const [AirBnbBooking, setAirBnbBooking] = useState(null);

  const CalnderRef = useRef();

  const NavTo = useNavigate();

  useEffect(() => {
    if (id) return;
    if (Places) {
      NavTo(`/dashboard/calendrier/${Places[0]?.Slog}`);
    }
  }, [id, Places]);

  useEffect(() => {
    if (!Places || SelectedPlace) return;
    setSelectedPlace({
      title: Places[0]?.Titre,
      img: Places[0]?.UrlImage,
      id: Places[0]?.Slog,
    });
  }, [Places]);

  const transformBookings = (bookings) => {
    return bookings?.map((booking) => {
      const title = booking?.Source === "Airbnb" ? "Airbnb" : "Booking";
      const image =
        booking?.Source === "Airbnb"
          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP5d2PcmA82jzAJe1QCz6tRxHgYjf43QM1hA&s"
          : "https://logos-world.net/wp-content/uploads/2021/08/Booking-Emblem.png";

      return {
        end: booking.EventTo,
        image: image,
        start: booking.EventFrom,
        title: title,
      };
    });
  };

  useEffect(() => {
    if (Places) return;
    async function handelGetBein() {
      try {
        const Places = await getBeinByUserId();

        setPlaces(Places);
        if (id && Places) return;
      } catch (error) {}
    }
    handelGetBein();
  }, [id]);

  useEffect(() => {
    if (!Slog) return;
    const UserId = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    const Currency = JSON.parse(localStorage.getItem("currency"));
    axios
      .post(
        "https://visiontunisie.com/api/v1/GestionDesReservations/selectReservationByBien",
        {
          Slog: Slog,
          Currency: Currency.code,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setReservation(response.data.reservations);
      })
      .catch((err) => {
        setReservation(null);
      });
  }, [ID_Bien, Places, Slog]);

  const convertToCalendarEvents = (reservations) => {
    return reservations?.map((reservation) => {
      let endDateObj = new Date(reservation?.checkOut);
      endDateObj.setDate(endDateObj.getDate() + 1);

      return {
        id: reservation?.ReservationUID,
        title: `${reservation.user?.Nom} ${reservation.user?.Prenom}`,
        start: reservation.checkIn?.split("T")[0],
        end: endDateObj.toISOString().split("T")[0],
        image: reservation?.user?.ImageProfile,
        Total: parseInt(reservation?.TotalTTC),
      };
    });
  };
  // Assuming filteredReservations contains your reservations data

  //  Adding the airbnb and booking :: booked dates

  useEffect(() => {
    const transformedBookings = transformBookings(AirBnbBooking);
    const reservations = convertToCalendarEvents(Reservation);
    const combinedReservations = [
      ...(transformedBookings || []),
      ...(reservations || []),
    ];

    setReservationErevts(combinedReservations);

    // setBookings(data);
  }, [DataOfAirbnb, DataOfBooking, Reservation, AirBnbBooking]);

  const eventPropGetter = (event) => {
    return {
      style: {
        backgroundColor:
          event.title == "Booking"
            ? "#003580"
            : event.title == "Airbnb"
              ? "#FF5A5F"
              : event.id == ResDetailsId && event.id
                ? "#172a4ce6"
                : "#172A4C",
        marginTop: "2.2rem",
        borderRadius: "10px",
      },
    };
  };

  const fetchDataBienDetails = async () => {
    setIsselectBeinLoading(true);
    const UserID = localStorage.getItem("ID_Utulisateur");
    const userToken = Cookies.get("Token");
    const Currency = JSON.parse(localStorage.getItem("currency"));
    try {
      const response = await axios.post(
        `https://visiontunisie.com/api/v1/GestionDesBiens/SelectBienID/`,
        {
          Slog: id,
          ID_Utulisateur: UserID,
          Currency: Currency.code,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setBookingLink(response?.data?.Biens?.URLbooking);
      setAirbnbLink(response?.data?.Biens?.URLairBnb);
      setSlog(response?.data?.Biens?.Slog);
      setCalendrUID(response?.data?.Biens?.CalendarUID);
      setAirBnbBooking(response?.data?.ForeignBookings);
      setPrices({
        menage: response?.data?.Biens?.Fraisdemenage,
        maxNights: response?.data?.Biens?.DureeMaximum,
        miniNights: response?.data?.Biens?.DureeMinimum,
        Prix: response?.data?.Biens?.Prix,
      });
      setCheckInAndOut({
        checkIn: response?.data?.Biens?.CheckInTime,
        checkOut: response?.data?.Biens?.CheckOutTime,
      });
      setNewPrices(response?.data?.NewPrice);
      setID_Bien(response?.data?.Biens?.ID_Bien);
      setIsselectBeinLoading(false);
      const blockDateStrings = response?.data?.BlockDates?.map(
        (item) => item.BlockDate
      );
      setBlokedDates(blockDateStrings);
      setReductions({
        re1: {
          value: response?.data?.ReductionsBiens?.ReductionHebdomadaire,
          isChecked: response?.data?.ReductionsBiens?.ReductionHebdomadaire > 0,
        },
        re2: {
          value: response?.data?.ReductionsBiens?.ReductionMensuel,
          isChecked: response?.data?.ReductionsBiens?.ReductionMensuel > 0,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsselectBeinLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchDataBienDetails();
  }, [id]);

  useEffect(() => {
    setSelectedSlots([]);
  }, [id]);

  const handleSelectSlot = (slotInfo) => {
    let slotsArary = [];
    slotInfo.slots.forEach((slot) => {
      const selectedDate = moment(slot).format("YYYY-MM-DD");
      slotsArary.push(selectedDate);
    });

    toggleSelection(
      slotsArary,
      ReservationErevts,
      setSelectedSlots,
      isPastDate
    );
  };

  const isPastDate = (date) => moment(date).isBefore(moment().startOf("day"));
  const isBlokedDate = (date) => {
    const BlokedDate = moment(date).subtract(0, "day").format("YYYY-MM-DD");
    return BlokedDates?.includes(BlokedDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getNewPriceForDate = (date) => {
    const IsNewPrice = NewPrices?.some(
      (item) => item.DateNewPrice === formatDate(date)
    );
    if (IsNewPrice) {
      const item = NewPrices.find(
        (item) => item.DateNewPrice === formatDate(date)
      );
      return item ? item.NouveauPrix : null;
    }
  };

  const isEventOnDay = (date) => {
    return ReservationErevts?.some((reservation) => {
      let reservationStartDate = new Date(reservation.start);
      reservationStartDate.setDate(reservationStartDate.getDate() - 1);
      const reservationEndDate = new Date(reservation.end);
      reservationEndDate.setDate(reservationEndDate.getDate() - 1);
      const currentDay = new Date(date);
      return (
        currentDay >= reservationStartDate && currentDay < reservationEndDate
      );
    });
  };

  return (
    <>
      <SeoHelmet
        pageTitle="Host Calendar"
        pageType="website"
        pageImage={
          "https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/dd1cf72d-324c-4fa7-948c-d349bbb1a300/public"
        }
      />
      <span className=" w-full  hidden  lg:block">
        <Navbar dashboard={true} NotFixed={true} />
      </span>
      <div className="   flex flex-col w-full items-center  relative ">
        <div className="w-full  items-start  bg-white  pb-5  lg:pb-16   gap-7 pt-5 flex px-[0rem] flex-row  md:text-base text-xs   text-left   lg:h-full h-[95vh] ">
          <div className=" flex h-[80vh]  sticky top-0 lg:h-[50rem]  flex-col  w-full lg:w-[80%] ">
            {/* DropDown */}
            <div
              onClick={() => setSelectedSlots([])}
              className=" flex  justify-end items-center gap-5 "
            >
              <button
                onClick={() => setIsEditOpen(true)}
                className=" lg:hidden flex px-5 py-2 rounded-md bg-main text-white "
              >
                <img
                  height={15}
                  width={15}
                  loading={"lazy"}
                  src={MiniHouse}
                  alt="MiniHouse"
                />
              </button>

              <span className=" w-full gap-3  py-4   flex items-center justify-end  ">
                <DropdownWithSearch
                  Places={Places}
                  SelectedPlace={SelectedPlace}
                  setSelectedPlace={setSelectedPlace}
                  id={id}
                />
              </span>
            </div>
            <div ref={CalnderRef} className=" lg:pt-0 pt-5 w-full h-full">
              <Calendar
                localizer={localizer}
                defaultView="month"
                selectable
                scrollToTime={true}
                longPressThreshold={100}
                events={ReservationErevts}
                views={["month"]}
                selected={selectedSlots}
                min={new Date()}
                onSelectSlot={handleSelectSlot}
                eventPropGetter={eventPropGetter}
                messages={messages}
                components={{
                  event: (props) => (
                    <EventRender
                      {...props}
                      event={props.event}
                      setResDetailsId={setResDetailsId}
                      setSelectedSlots={setSelectedSlots}
                    />
                  ),
                  toolbar: CustomToolbar,
                  dateCellWrapper: (props) => (
                    <CustomDateCellWrapper
                      {...props}
                      selectedSlots={selectedSlots}
                      isBlokedDate={isBlokedDate} // Adjust as per your logic
                      isEventOnDay={isEventOnDay} // Adjust as per your logic
                      IsselectBeinLoading={IsselectBeinLoading} // Adjust as per your state
                      getNewPriceForDate={getNewPriceForDate} // Adjust as per your logic
                      Prices={Prices} // Adjust as per your data
                    />
                  ),
                  month: {
                    dateHeader: (props) => (
                      <div className=" hidden ">{props.label}</div>
                    ),
                  },
                }}
              />
            </div>
          </div>
          {/* left Side */}
          <div
            className={` fixed cursor-default lg:relative top-0  duration-150 ease-in lg:px-0 px-[1rem]  w-full  bg-white  lg:z-20 z-[100]  ${
              IsEditOpen ? "right-0" : " lg:right-0 -right-[100%] "
            }  top-0   lg:w-[30%] h-full overflow-x-hidden flex flex-col pt-20   py-10`}
          >
            {ResDetailsId && selectedSlots?.length <= 0 ? (
              <span className=" w-full flex relative  ">
                <ReservationsDetails
                  IsCalander={true}
                  filteredReservations={Reservation}
                  ResDetailsId={ResDetailsId}
                  setResDetailsId={setResDetailsId}
                />
              </span>
            ) : (
              <>
                <span className=" absolute top-0  w-full h-12 right-0">
                  <Topbar
                    onClick={() => setIsEditOpen(false)}
                    text={tarifs ? "Mes Tarifs" : " Mes Disponibilites"}
                  />
                </span>
                <div
                  className={` ${
                    selectedSlots.length > 0 ? " right-0" : " -right-[100%] "
                  } duration-150 ease-in-out absolute top-0 z-40 w-full h-full bg-white  items-center  hidden lg:flex flex-col`}
                >
                  <span
                    onClick={() => setSelectedSlots([])}
                    className=" cursor-pointer absolute top-3 right-3 "
                  >
                    <CloseIcon />
                  </span>
                  <UpdateSelectedDatesRange
                    setNewPrices={setNewPrices}
                    Slog={Slog}
                    setSelectedSlots={setSelectedSlots}
                    BlokedDates={BlokedDates}
                    selectedDates={selectedSlots}
                    setBlokedDates={setBlokedDates}
                    Prix={Prices.Prix}
                    NewPrices={NewPrices}
                  />
                </div>
                <div className="   lg:h-[90vh]  lg:pr-3  ">
                  <div className=" flex pb-3 mb-3 border-b-[1px] ">
                    <button
                      onClick={() => setTarifs(true)}
                      className={`p-2 px-3  w-full ${
                        tarifs ? "bg-main text-white" : "bg-white text-main"
                      }  rounded-lg `}
                    >
                      {t("HostMode.Calendrier.MyPrices")}
                    </button>
                    <button
                      onClick={() => setTarifs(false)}
                      className={`p-2 px-3  w-full ${
                        !tarifs ? "bg-main text-white" : "bg-white text-main"
                      } rounded-lg`}
                    >
                      {t("HostMode.Calendrier.MyAvailability")}
                    </button>
                  </div>

                  {tarifs ? (
                    <Tarifs
                      Slog={Slog}
                      setClose={setIsEditOpen}
                      Reductions={Reductions}
                      setReductions={setReductions}
                      update={true}
                      Prices={Prices}
                      setPrices={setPrices}
                    />
                  ) : (
                    <Disponibilites
                      Slog={Slog}
                      fetchDataBienDetails={fetchDataBienDetails}
                      setClose={setIsEditOpen}
                      CheckInAndOut={CheckInAndOut}
                      setCheckInAndOut={setCheckInAndOut}
                      Prices={Prices}
                      setPrices={setPrices}
                      CalendrUID={CalendrUID}
                      AirbnbLink={AirbnbLink}
                      BookingLink={BookingLink}
                      setBookingLink={setBookingLink}
                      setAirbnbLink={setAirbnbLink}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {/* Mobile edit selected dates */}
        <div
          className={` ${
            selectedSlots?.length > 0 ? " bottom-[0%] " : "bottom-[-100%]"
          } duration-150 ease-in-out shadow-xl rounded-t-[3rem] border-[1px]  fixed  right-0 z-40 w-full h-[50%] bg-white  items-center  lg:hidden flex flex-col`}
        >
          <span
            onClick={() => setSelectedSlots([])}
            className=" cursor-pointer text absolute h-10   top-4 right-5 "
          >
            <CloseIcon className=" z-20" />
          </span>
          <UpdateSelectedDatesRange
            Slog={Slog}
            setNewPrices={setNewPrices}
            setSelectedSlots={setSelectedSlots}
            BlokedDates={BlokedDates}
            selectedDates={selectedSlots}
            setBlokedDates={setBlokedDates}
            Prix={Prices.Prix}
            NewPrices={NewPrices}
          />
        </div>
      </div>
      <span className=" w-full z-20">
        <MobileMenuBar dashboard={true} />
      </span>
    </>
  );
}

export default Calendrier;
