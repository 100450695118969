import React, { useState } from "react";
import "./AiButton.css";
import LoadingBtn from "../ui/LoadingBtn";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
function AiButton({
  IsDisabled,
  isAnalyse,
  setTitleAndDescription,
  TitleAndDescription,
  isItEmpty,
  OnClick,
  isEnglish,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  async function HandelGetTranslation() {
    const userToken = Cookies.get("Token");
    setIsLoading(true);
    if (isEnglish) {
      try {
        const response = await axios.post(
          "https://visiontunisie.com/api/v1/GestionDesBiens/DescriptionAnglais",
          {
            MessageATraduire: TitleAndDescription?.description,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        setTitleAndDescription((prev) => ({
          ...prev,
          descriptionEn: response.data,
        }));
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        console.error("Login failed. Error:", error.message);
        throw error;
      }
    } else {
      try {
        const response = await axios.post(
          "https://visiontunisie.com/api/v1/GestionDesBiens/DescriptionSpanish",
          {
            MessageATraduire: TitleAndDescription?.description,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        setTitleAndDescription((prev) => ({
          ...prev,
          descriptionEs: response.data,
        }));
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);

        console.error("Login failed. Error:", error.message);
        throw error;
      }
    }
  }

  return (
    <button
      data-tooltip-id="my-tooltip"
      data-tooltip-content={t("Host.Description&Title.AiButtonToolTipe")}
      onClick={isAnalyse ? OnClick :HandelGetTranslation }
      disabled={IsDisabled}
      className={
        !IsDisabled
          ? " py-[0.6rem] px-1 pr-4 text-sm border-[1px] btn text-sec  relative"
          : " py-[0.6rem] px-1 pr-4 text-sm border-[1px] Disbtn text-white bg-opacity-35 border-none bg-gray-200 relative"
      }
    >
      {IsDisabled && <Tooltip id="my-tooltip" />}
      <span className=" rounded-full overflow-hidden z-50">
        <LoadingBtn isLoading={isLoading} rounded={true} />
      </span>

      {
        <>
          <svg
            height="21"
            width="21"
            fill="#FFFFFF"
            viewBox="0 0 24 24"
            data-name="Layer 1"
            id="Layer_1"
            className="sparkle"
          >
            <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
          </svg>

          <span className="text">{isItEmpty && !isAnalyse ? "Re Generate" : "SuperHost AI"}</span>
        </>
      }
    </button>
  );
}

export default AiButton;
